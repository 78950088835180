@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;




@layer utilities {
    input:-webkit-autofill {
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0 50px white inset !important;
    }
  
    .dark input:-webkit-autofill {
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0 50px #313131 inset !important;
      -webkit-text-fill-color: white !important; 
    }
  }






@layer base {
  h5 {
    @apply absolute px-6 duration-200 w-52 bottom-4 md:bottom-8 md:px-10 group-hover:scale-110 group-hover:text-black;
  }
}

/* @layer components {
  .btn {
    @apply px-10 py-2 my-0 font-bold tracking-widest uppercase border-2 border-black  hover:bg-black hover:text-white;
  }
} */

.item-container {
  @apply flex flex-col justify-between w-full space-y-6 text-2xl text-white uppercase md:flex-row md:space-y-0 md:space-x-8;
}

.item {
  @apply relative overflow-hidden md:w-1/4;
}

.item-gradient {
  @apply absolute top-0 bottom-0 right-0 left-0 bg-gradient-to-b from-transparent to-gray-900 group-hover:from-gray-50 group-hover:to-white group-hover:opacity-70;
}

/* #hero {
  background-image: url('../images/desktop/image-hero.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 576px) {
  #hero {
    background-image: url('../images/mobile/image-hero.jpg');
    background-position: center;
  }
} */

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;  
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

.primary {
    color: #154298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
    border-width: 1px;
    border-left: solid;
}
.secondary {
    color: #4c5055;
    background-color: #e2e3e5;
    border-color: #d3d6d8;
    border-width: 1px;
    border-left: solid;
}
.success {
    color: #105132;
    background-color: #d1e7dd;
    border-color: #badbcc;
    border-width: 1px;
    border-left: solid;
}
.danger {
    color: #842129;
    background-color: #f8d7da;
    border-color: #f2c0c5;
    border-width: 1px;
    border-left: solid;
}
.warning {
    color: #664d03;
    background-color: #fef3cd;
    border-color: #f2e1ad;
    border-width: 1px;
    border-left: solid;
}
.info {
    color: #175867;
    background-color: #cff4fc;
    border-color: #b6effb;
    border-width: 1px;
    border-left: solid;
}
.light {
    color: #636464;
    background-color: #fefefe;
    border-color: #bcbebf;
    border-width: 1px;
    border-left: solid;
}
.dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf;
    border-width: 1px;
    border-left: solid;
}
